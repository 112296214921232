import { Col, Row, Skeleton, Table } from 'antd'
import NotLoggedIn from '../pages/NotLoggedIn'
import React from 'react'
import { Link } from 'react-router-dom'
import LoadError from '../components/LoadError'
import gql from 'graphql-tag'
import { CircleFlag } from 'react-circle-flags'
import countries from './world_countries.json'
import { Query } from 'react-apollo'
import { ResponsiveChoropleth } from '@nivo/geo'

import Gravatar from 'react-gravatar'
import moment from 'moment'
import CultivarRecordsChart from '../components/CultivarRecordsChart'

const PROFILE_INFORMATION = gql`
   query($id: String!) {
      performanceCultivar(user: { uid: $id }) {
         key: id
         commonName
         cropType
         targetProduct
         species {
            id
            latinName
            commonName
         }
         plantDate
         peakHarvestDate
         productionMethod
         dateCreated: createdAt
         country {
            id: isoAlpha3Code
            isoAlpha2Code
            isoAlpha3Code
            countryName
         }
         user {
            id
            firstName
            lastName
            address
            email
            country {
               id: m49Code
               countryName
               regionName
               subRegionName
            }
         }
      }
      users(uid: $id) {
         id
         createdAt
         country {
            id: m49Code
            isoAlpha2Code
            isoAlpha3Code
         }
      }
   }
`

const columns = [
   {
      title: 'ID',
      dataIndex: 'key',
      key: 'address 3',
      width: 50,
      render: (value, row, index) => {
         return <Link to={`/view-cultivar/${value}`}>{value}</Link>
      },
   },
   {
      title: 'Common Name',
      dataIndex: ['species', 'commonName'],
      key: 'commonName',
   },
   {
      title: 'Species Name',
      dataIndex: ['species', 'latinName'],
      key: 'species.latinName',
   },
   {
      title: 'Target Product',
      dataIndex: 'targetProduct',
      key: 'targetProduct',
   },
   {
      title: 'Production Method',
      dataIndex: 'productionMethod',
      key: 'productionMethod',
   },
   {
      title: 'Plant Date',
      dataIndex: 'plantDate',
      key: 'plantDate',
      ellipsis: true,
   },
   {
      title: 'Peak Date',
      dataIndex: 'peakHarvestDate',
      key: 'peakHarvestDate',
      ellipsis: true,
   },
]

const ViewProfile = (props) => {
   const map = []

   return (
      <>
         {props.authUser ? (
            <>
               <p className="page-header">User Profile</p>
               <Query
                  query={PROFILE_INFORMATION}
                  variables={{ id: props.authUser.uid }}
                  fetchPolicy={'network-only'}
               >
                  {({ loading, error, data }) => {
                     if (loading)
                        return (
                           <div>
                              <Skeleton />
                           </div>
                        )
                     if (error) return <LoadError />

                     const performa = data.performanceCultivar
                     const countries_data = data.performanceCultivar

                     countries_data.map((country) =>
                        map.push({ id: country.country.id, value: 1 })
                     )

                     let result = map.reduce(function (acc, curr) {
                        let isElemExist = acc.findIndex(function (item) {
                           return item.id === curr.id
                        })
                        if (isElemExist === -1) {
                           let obj = {}
                           obj.id = curr.id
                           obj.value = 1
                           acc.push(obj)
                        } else {
                           acc[isElemExist].value += 1
                        }
                        return acc
                     }, [])

                     return (
                        <Row>
                           <Col span={8}>
                              <Row>
                                 <Col span={8}>
                                    <Gravatar
                                       email={props.authUser.email}
                                       size={110}
                                       className="gravatar-icon"
                                    />
                                 </Col>
                                 <Col span={16}>
                                    <div className="profile-information">
                                       <p className="name-tag">
                                          {props.authUser.displayName}{' '}
                                          <CircleFlag
                                             height="25"
                                             countryCode={data.users[0].country.isoAlpha2Code.toLowerCase()}
                                          />
                                       </p>
                                       <p>
                                          Email: {props.authUser.email}
                                          <br />
                                          Registered:{' '}
                                          {moment(
                                             data.users[0].createdAt
                                          ).format('MMMM Do YYYY')}
                                       </p>
                                    </div>
                                 </Col>
                              </Row>
                           </Col>
                           <Col className="search-results-main" span={16}>
                              <div
                                 style={{ height: '400px' }}
                                 className="search-results-map map"
                              >
                                 <ResponsiveChoropleth
                                    data={result}
                                    features={countries.features}
                                    colors="nivo"
                                    domain={[0, 1000000]}
                                    unknownColor="#dfdddd"
                                    label="properties.name"
                                    valueFormat=".2s"
                                    projectionTranslation={[0.5, 0.5]}
                                    projectionRotation={[0, 0, 0]}
                                    enableGraticule={false}
                                    graticuleLineColor="#dddddd"
                                    borderWidth={0.5}
                                    borderColor="#152538"
                                    legends={[
                                       {
                                          anchor: 'bottom-left',
                                          direction: 'column',
                                          justify: true,
                                          translateX: 20,
                                          translateY: -10,
                                          itemsSpacing: 0,
                                          itemWidth: 94,
                                          itemHeight: 18,
                                          itemDirection: 'left-to-right',
                                          itemTextColor: '#444444',
                                          itemOpacity: 0.85,
                                          symbolSize: 18,
                                          effects: [
                                             {
                                                on: 'hover',
                                                style: {
                                                   itemTextColor: '#000000',
                                                   itemOpacity: 1,
                                                },
                                             },
                                          ],
                                       },
                                    ]}
                                 />
                              </div>
                              <br />
                              {performa &&
                                 !!performa.length &&
                                 performa.map((link) => (
                                    <Link to={''} key={link.key} link={link} />
                                 ))}
                              <Table
                                 className="search-results-table"
                                 columns={columns}
                                 dataSource={performa}
                              />
                           </Col>
                        </Row>
                     )
                  }}
               </Query>
            </>
         ) : (
            <NotLoggedIn />
         )}
      </>
   )
}

export default ViewProfile
