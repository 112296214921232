import { Col, Row, Skeleton, Table } from "antd";

import { Link } from "react-router-dom";
import LoadError from "../components/LoadError";
import CultivarsViz from "../components/TableauCultivars";
import { ResponsiveChoropleth } from "@nivo/geo";
import countries from "./world_countries.json";
import { CircleFlag } from "react-circle-flags";
import { Query } from "react-apollo";
import React from "react";
import { Spin } from "antd";
import gql from "graphql-tag";
import moment from "moment";

const columns = [
  {
    title: "ID",
    dataIndex: ["node", "key"],
    key: "id",
    width: 80,
    render: (value) => {
      return <Link to={`/view-cultivar/${value}`}>{value}</Link>;
    },
  },
  {
    title: "Common Name",
    dataIndex: ["node", "commonName"],
    key: "commonName",
  },
  {
    title: "Target Product",
    dataIndex: ["node", "targetProduct"],
    key: "targetProduct",
  },
  {
    title: "Production Method",
    dataIndex: ["node", "productionMethod"],
    key: "productionMethod",
  },
  {
    title: "Date Created",
    dataIndex: ["node", "dateCreated"],
    key: "plantDate",
    render: (value) => {
      return moment(value).format("MMMM Do YYYY");
    },
  },
  {
    title: "Country",
    dataIndex: ["node", "country", "isoAlpha2Code"],
    key: "isoAlpha2Code",
    render: (value) => {
      return <CircleFlag countryCode={value.toLowerCase()} height="20" />;
    },
  },
];

const ALL_CULTIVARS_QUERY = gql`
  {
    allPerformanceCultivars(first: 100) {
      edges {
        node {
          id
          key: id
          commonName
          country {
            id: isoAlpha3Code
            isoAlpha2Code
          }
          plantDate
          targetProduct
          productionMethod
          dateCreated: createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

class ListCultivars extends React.Component {
  componentDidMount() {
    document.title = "Hortivar - Performance Cultivars Database";
  }

  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    map: [
      {
        id: "",
        value: "",
      },
    ],
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const map = [];

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <>
        <Row>
          <Col className="search-results-sidebar" span={8}>
            <div className="search-results-photos">
              <CultivarsViz />
            </div>
          </Col>
          <Col span={16} className="search-results-main">
            <p></p>
            <div style={{ height: "400px" }} className="search-results-map map">
              <Query query={ALL_CULTIVARS_QUERY} fetchPolicy={"cache-first"}>
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <div className="spinner">
                        <Spin size="large" tip="Loading visualization..." />
                      </div>
                    );
                  if (error) return <LoadError />;

                  const countries_data = data.allPerformanceCultivars.edges;
                  countries_data.map((country) =>
                    map.push({ id: country.node.country.id, value: 1 })
                  );

                  let result = map.reduce(function (acc, curr) {
                    let isElemExist = acc.findIndex(function (item) {
                      return item.id === curr.id;
                    });
                    if (isElemExist === -1) {
                      let obj = {};
                      obj.id = curr.id;
                      obj.value = 1;
                      acc.push(obj);
                    } else {
                      acc[isElemExist].value += 1;
                    }
                    return acc;
                  }, []);

                  return (
                    <ResponsiveChoropleth
                      data={result}
                      features={countries.features}
                      colors="nivo"
                      domain={[0, 1000]}
                      unknownColor="#dfdddd"
                      label="properties.name"
                      valueFormat=".2s"
                      projectionTranslation={[0.5, 0.6]}
                      projectionRotation={[0, 0, 0]}
                      enableGraticule={false}
                      projectionScale={115}
                      graticuleLineColor="#dddddd"
                      borderWidth={0.5}
                      borderColor="#152538"
                      legends={[
                        {
                          anchor: "bottom-left",
                          direction: "column",
                          justify: true,
                          translateX: 20,
                          translateY: -10,
                          itemsSpacing: 0,
                          itemWidth: 94,
                          itemHeight: 18,
                          itemDirection: "left-to-right",
                          itemTextColor: "#444444",
                          itemOpacity: 0.85,
                          symbolSize: 18,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemTextColor: "#000000",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  );
                }}
              </Query>
            </div>
            <br />
            <p className="cultivar-h2">Recent Submissions (100)</p>
            <Query query={ALL_CULTIVARS_QUERY} fetchPolicy={"cache-first"}>
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <div>
                      <Skeleton />
                    </div>
                  );
                if (error) return <LoadError />;

                const performa = data.allPerformanceCultivars.edges;

                console.log(performa);

                return (
                  <Table
                    rowKey={(columns) => columns.node.key}
                    rowSelection={rowSelection}
                    className="search-results-table"
                    columns={columns}
                    dataSource={performa}
                    onChange={(pagiation, filters, sorters) => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0;
                    }}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                    }}
                  />
                );
              }}
            </Query>
          </Col>
        </Row>
      </>
    );
  }
}

export default ListCultivars;
