import { Button, Col, Modal, Form, Row, Steps, message } from 'antd'
import {
   CaretLeftOutlined,
   CaretRightOutlined,
   PlusOutlined,
   SaveOutlined,
} from '@ant-design/icons'
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import CroppingAndYield from '../components/create_cultivars/CroppingAndYield'
import Cultivar from '../components/create_cultivars/Cultivar'
import GeoBounderies from '../components/create_cultivars/GeoBounderies'
import NotLoggedIn from '../pages/NotLoggedIn'
import Production from '../components/create_cultivars/Production'
import Source from '../components/create_cultivars/Source'
import Uploads from '../components/Uploads'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useStepsForm } from 'sunflower-antd'

const { Step } = Steps

const CREATE_CULTIVAR_MUTATION = gql`
   mutation createPerformanceCultivar($input: CreatePerformanceCultivarInput!) {
      createPerformanceCultivar(input: $input) {
         errors
         performanceCultivar {
            id
            user {
               id
            }
         }
      }
   }
`

const CultivarsPage = (props) => {
   const history = useHistory()

   const [state, setState] = useState({
      current: 0,
      commonName: '',
      species: '',
      cultivarType: '',
      users: '',
      cropType: '',
      grafted: false,
      country: 'Country',
      longitude: '',
      latitude: '',
      altitude: '',
      unit: '',
      unitYield: '',
      density: '',
      seedSystem: '',
      firstAmount: '',
      peakAmount: '',
      lastAmount: '',
      productionSystem: '',
      productionMethod: '',
      recordEnvironment: '',
      cultureMedia: '',
      targetProduct: '',
      countryName: '',
      cordinates: '',
      plantDate: '',
      startHarvestDate: '',
      peakHarvestDate: '',
      lastHarvestDate: '',
      circleValue: '',
      modal1Visible: false,
      modal2Visible: false,
      validForm: false,
   })

   const [createCultivar] = useMutation(CREATE_CULTIVAR_MUTATION, {
      variables: {
         input: {
            attributes: {
               commonName: state.commonName,
               species: parseInt(state.species),
               cultivarType: parseInt(state.cultivarType),
               cropType: parseInt(state.cropType),
               userId: state.users,
               longitude: parseFloat(state.longitude),
               latitude: parseFloat(state.latitude),
               targetProduct: parseInt(state.targetProduct),
               productionSystem: parseInt(state.productionSystem),
               productionMethod: parseInt(state.productionMethod),
               recordEnvironment: parseInt(state.recordEnvironment),
               density: parseFloat(state.density),
               unitYield: parseFloat(state.unitYield),
               unit: parseInt(state.unit),
               firstAmount: parseFloat(state.firstAmount),
               peakAmount: parseFloat(state.peakAmount),
               lastAmount: parseFloat(state.lastAmount),
               seedSystem: parseInt(state.seedSystem),
               cultureMedia: parseInt(state.cultureMedia),
               plantDate: moment(state.plantDate).format('YYYY-MM-DD'),
               startHarvestDate: moment(state.startHarvestDate).format(
                  'YYYY-MM-DD'
               ),
               peakHarvestDate: moment(state.peakHarvestDate).format(
                  'YYYY-MM-DD'
               ),
               lastHarvestDate: moment(state.lastHarvestDate).format(
                  'YYYY-MM-DD'
               ),
               notes: 'NA',
               circleValue: parseFloat(state.circleValue),
               country: parseInt(state.country),
            },
         },
      },
      onCompleted: (data) => {
         history.push(
            '/view-cultivar/' +
               data.createPerformanceCultivar.performanceCultivar.id
         )
      },
   })

   const onChange = (current) => {
      setState({ current })
   }

   const handleSelectChange = (event, label) =>
      setState({
         ...state,
         [label]: event,
      })

   const handleChangeSelectLabel = (e, label) =>
      setState({
         ...state,
         countryName: label.children,
      })

   const handleChangeCountryName = (e) => setState({ countryName: 'Auz' })

   const handleChange = (e) =>
      setState({ ...state, [e.target.name]: e.target.value })

   const handleChangeBool = (e) =>
      setState({ ...state, grafted: !state.grafted })

   const setModal1Visible = (modal1Visible) => {
      setState({ ...state, modal1Visible })
   }

   const setModal2Visible = (modal2Visible) => {
      setState({ ...state, modal2Visible })
   }

   const prev = () => {
      const current = state.current - 1
      setState({ ...state, current })
   }

   const steps = [
      <Cultivar
         {...state}
         handleChange={handleChange}
         handleChangeBool={handleChangeBool}
         handleSelectChange={handleSelectChange}
      />,
      <GeoBounderies
         {...state}
         handleChange={handleChange}
         handleChangeBool={handleChangeBool}
         handleSelectChange={handleSelectChange}
         handleChangeSelectLabel={handleChangeSelectLabel}
      />,
      <Production
         {...state}
         handleChangeCountryName={handleChangeCountryName}
         handleSelectChange={handleSelectChange}
         handleChange={handleChange}
      />,
      <CroppingAndYield
         {...state}
         handleSelectChange={handleSelectChange}
         handleChange={handleChange}
      />,
      <Source />,
   ]

   const { current } = state

   const next = () => {
      const current = state.current + 1
      state.users = props.authUser.uid
      setState({ ...state, current })
   }

   return (
      <>
         {props.authUser ? (
            <Row>
               <Col className="cultivar-sidebar" span={4}>
                  <Form
                     name="basic"
                     initialValues={{ remember: true }}
                     autoComplete="off"
                  >
                     <Steps
                        current={current}
                        onChange={onChange}
                        direction="vertical"
                     >
                        <Step title="Cultivar" icon={<PlusOutlined />} />
                        <Step title="Geo-Boundaries" icon={<PlusOutlined />} />
                        <Step title="Production" icon={<PlusOutlined />} />
                        <Step
                           title="Cropping & Yield"
                           icon={<PlusOutlined />}
                        />
                        <Step title="Source" icon={<PlusOutlined />} />
                     </Steps>
                  </Form>
               </Col>
               <Col span={12} className="create-cultivar">
                  <Form
                     name="basic"
                     initialValues={{
                        remember: true,
                     }}
                  >
                     <div className="steps-content">{steps[current]}</div>
                     <div className="steps-action">
                        {current < steps.length - 1 && (
                           <Button
                              type="primary"
                              onClick={() => next()}
                              className="create-cultivar-save-btn"
                           >
                              Next <CaretRightOutlined />
                           </Button>
                        )}
                        {current === steps.length - 1 && (
                           <Button
                              type="primary"
                              onClick={() => {
                                 createCultivar()
                                 message.success('Processing complete!')
                              }}
                              className="create-cultivar-save-btn-final"
                           >
                              Submit <SaveOutlined />
                           </Button>
                        )}
                        {current > 0 && (
                           <Button
                              style={{ margin: '0 8px' }}
                              onClick={() => prev()}
                              className="create-cultivar-save-btn-prev"
                           >
                              <CaretLeftOutlined /> Previous
                           </Button>
                        )}
                     </div>
                  </Form>
               </Col>
               <Col span={8} className="create-cultivar-right">
                  <Button
                     type="primary"
                     onClick={() => setModal1Visible(true)}
                     className="create-cultivar-modal"
                  >
                     <PlusOutlined /> URLs Embed
                  </Button>
                  <Modal
                     title="URLs Embed"
                     centered
                     visible={state.modal1Visible}
                     onOk={() => setModal1Visible(false)}
                     onCancel={() => setModal1Visible(false)}
                     className="cultivar-popover"
                  >
                     <p>Under development...</p>
                  </Modal>

                  <Button
                     type="primary"
                     onClick={() => setModal2Visible(true)}
                     className="create-cultivar-modal"
                  >
                     <PlusOutlined /> Media Upload
                  </Button>
                  <Modal
                     title="Media Upload"
                     centered
                     visible={state.modal2Visible}
                     onOk={() => setModal2Visible(false)}
                     onCancel={() => setModal2Visible(false)}
                     className="cultivar-popover"
                  >
                     <Uploads />
                  </Modal>
               </Col>
            </Row>
         ) : (
            <NotLoggedIn />
         )}
      </>
   )
}

export default CultivarsPage
